import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GlutenFree from '../GlutenFree/index';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { font, space, color } = theme;

const FoodItemStyles = styled.div`
  font-family: ${font.font};
  margin-bottom: 4em;

  &:first-of-type {
    margin-top: 2em;
  }

  p.large {
    margin-bottom: 0.5em;

    @media ${media.secondary} {
      line-height: 1.35;
    }
  }

  .food-item-name {
    ${mixins.flexBetween};
    margin-bottom: ${space.quarterSpace};
    color: ${color.foreground};
    font-family: ${font.fontItalic};
    font-weight: bold;
    font-size: 2em;

    @media ${media.secondary} {
      font-size: 1.65em;
    }
  }

  .food-item-desc {
    margin-bottom: ${space.halfSpace};
  }

  .food-item-options-desc {
    color: ${color.typeDim};
    font-size: 1em;
    margin-bottom: 0.85em;
    margin-top: 0.85em;

    @media ${media.secondary} {
      font-size: 1.35em;
      margin-top: 0.65em;
    }

    @media ${media.smallPhone} {
      font-size: 1.25em;
      margin-top: 0.45em;
    }
  }

  .food-item-options {
    &-title {
      display: flex;
      align-items: center;
      color: ${color.foreground};
      text-transform: uppercase;
      font-family: ${font.bold};
      letter-spacing: 0.25em;
      font-size: 0.75em;

      @media ${media.secondary} {
      }

      @media ${media.smallPhone} {
      }
    }

    margin-top: 1.65em;
    margin-bottom: 0.85em;

    @media ${media.secondary} {
      font-size: 0.65em;
    }

    svg {
      fill: green;
      height: 1.25em;
      margin-right: 0.65em;
      width: 1.25em;
    }
  }

  .food-item-price {
    font-size: 0.85em;
    font-family: ${font.boldAlt};
    text-transform: uppercase;
    color: ${color.callout};
  }
`;

const FoodItem = ({ data }) => (
  <FoodItemStyles>
    <div className="food-item-name">
      <div>{data.name}</div>
    </div>
    {data.dietary ? (
      <div className="food-item-options">
        <div className="food-item-options-title">
          <GlutenFree />
          {data.dietary ? `${data.dietary.title}` : null}
        </div>
        {data.dietary && data.dietary.subtitle ? (
          <div className="food-item-options-desc">{data.dietary.subtitle}</div>
        ) : null}
      </div>
    ) : null}

    <p className="large dim">
      {data.desc ? data.desc : null}
      <br />
    </p>

    <div className="food-item-price">
      {' '}
      {data.price
        ? `${
            typeof data.price === 'object'
              ? `${data.price.small} / ${data.price.large}`
              : `${data.price}`
          }`
        : ''}
    </div>
  </FoodItemStyles>
);

FoodItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    desc: PropTypes.string,
    price: {
      small: PropTypes.number,
      large: PropTypes.number,
    },
    dietary: {
      type: PropTypes.string,
      title: PropTypes.string,
    },
  }).isRequired,
};

export default FoodItem;
