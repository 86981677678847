import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { color } = theme;

const StyledLinkInternal = styled((props) => <Link {...props} />)`
  ${mixins.animate}
	${mixins.cta}
  color: ${({ dark }) =>
    dark ? `${color.background}` : `${color.foreground}`};
  background-color: ${({ filled }) =>
    filled ? `${color.backgroundAlt}` : `none`};
  border: ${({ filled }) =>
    filled ? `2px solid ${color.backgroundAlt}` : `2px solid ${color.callout}`};

  &:hover {
    color: ${({ filled }) =>
      filled ? `${color.foreground}` : `${color.foreground}`};
    background-color: ${({ filled }) =>
      filled ? `${color.callout}` : `${color.callout}`};
    border: 2px solid ${color.callout};
`;

const LinkInternal = ({ text, dark, href, title, filled }) => (
  <StyledLinkInternal
    className="cta"
    to={href}
    title={title}
    dark={dark}
    filled={filled}
  >
    {text}
  </StyledLinkInternal>
);

LinkInternal.propTypes = {
  text: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  filled: PropTypes.bool,
};

export default LinkInternal;
