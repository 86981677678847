import React from 'react';

// Components
import SectionHeading from '../SectionHeading/index';
import LinkInternal from '../LinkInternal/index';

function ThePocoExperience() {
  return (
    <section className="no-padding-top">
      <div className="wrap">
        <SectionHeading
          title="Welcome to poco"
          text={['THE POCO', <span>experience</span>]}
          id="locations"
        />
        <p className="large dim">
          <em>Poco Piatti means "small plates."</em> Here, you have the
          opportunity to experience the many{' '}
          <em>flavors of the mediterranean! </em>
          We strive to create dishes that are <em>unique</em> and{' '}
          <em>healthy</em> using only the most <em>fresh</em> and best quality
          ingredients. So <em>share</em> the small, or large, plates with your
          table and <em>enjoy!</em>
        </p>
        <LinkInternal
          text="Explore Our Menu"
          href="/menu"
          title="View Poco Piatti's online menu."
        />
      </div>
    </section>
  );
}

export default ThePocoExperience;
