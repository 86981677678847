import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Styles
import theme from '../../styles/theme';

const { color, font } = theme;
const GlobalHeaderStyle = styled.div`
  backdrop-filter: blur(0.8em);
  color: ${color.foreground};
  font-size: 1em;
  padding: 1.5em 0;
  position: relative;
  width: 100%;
  z-index: 10;

  .wrap {
    text-align: center;
    line-height: 1.4;

    a {
      color: ${color.callout};
      padding: 0;
    }
  }

  .global-header-link {
    color: ${color.callout};
    font-family: ${font.fontItalic};
    text-decoration: underline;
    padding: 0;
    text-transform: none;
    font-size: 1em;
    letter-spacing: 0;
    cursor: pointer;
    padding: 0;
  }
`;

const GlobalHeader = () => (
  <GlobalHeaderStyle>
    <div className="wrap">
      <a
        href="https://thegardenbypocopiatti.com/"
        target="_blank"
        rel="noreferrer"
      >
        The Garden by Poco Piatti
      </a>{' '}
      is now open at Glass City Metropark.
    </div>
  </GlobalHeaderStyle>
);

// const GlobalHeaderStyle = styled.div`
//   backdrop-filter: ${({ light }) => (light ? `blur(0.8em)` : `none`)};
//   background-color: ${({ light }) =>
//     light ? `rgba(20, 20, 21, 0.1)` : `${color.backgroundTert}`};
//   color: ${color.background};
//   padding: 1em 0;
//   width: 100%;

//   .wrap {
//     text-align: center;
//     font-size: 0.9em;

//     a {
//       padding: 0;
//     }
//   }

//   .global-header-link {
//     color: ${color.callout};
//     font-family: ${font.fontItalic};
//     text-decoration: underline;
//     padding: 0;
//     text-transform: none;
//     font-size: 1em;
//     letter-spacing: 0;
//     cursor: pointer;
//     padding: 0;
//   }
// `;

// const GlobalHeader = ({ light }) => (
//   <GlobalHeaderStyle light={light}>
//     <div className="wrap">
//       Now offering{' '}
//       <Link to="/Order">
//         <span className="global-header-link">delivery</span>
//       </Link>{' '}
//       from our Sylvania and Perrysburg locations!
//     </div>
//   </GlobalHeaderStyle>
// );

// GlobalHeader.propTypes = {
//   light: PropTypes.bool,
// };

export default GlobalHeader;
