const CateringImagesData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4280.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4282.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4283.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4287.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4290.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4291.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4297.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4298.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4303.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4305.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4306+2-1.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4313.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4317.webp',
  },
];

export default CateringImagesData;
