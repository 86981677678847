const theme = {
  responsive: {
    wrap: 'calc(100% - 10rem)',
    wrapMobile: '85%',
  },

  border: {
    radius: '1.2em',
  },

  space: {
    space: '2em',
    halfSpace: '1em',
    quarterSpace: '0.5em',
    gap: '2em',
  },

  grid: {
    gap: '2em',
  },

  color: {
    background: '#000000',
    backgroundAlt: '#302723',
    backgroundTert: '#719392',
    // foreground: '#F0EDE5',
    foreground: '#f5f9e9',
    foregroundAlt: '#040303',
    // callout: '#EAC798',
    callout: '#c18c5d',
    calloutAlt: '#DD8478',
    foregroundDim: '#707070',
    typeAlt: 'rgba(0, 0, 0, 0.6)',
    typeDim: '#707070',
  },

  font: {
    font: 'eb_garamondregular',
    fontItalic: 'eb_garamonditalic',
    fontMedium: 'eb_garamondregular',
    fontLight: 'eb_garamondregular',
    bold: 'sp_valley_sansregular',
    boldAlt: 'sp_valley_sansregular_rough',
    title: 'saekana_roughregular',
  },
};

export default theme;
