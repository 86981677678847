import React from 'react';
import { Helmet } from 'react-helmet';

// Components
// import Curtain from '../../components/Curtain/index';
import Header from '../../components/Header/index';
import HomeHero from '../../components/HomeHero/index';
import ThePocoExperience from '../../components/ThePocoExperience';
import HensvilleCallout from '../../components/HensvilleCallout/index';
import Locations from '../../components/Locations/index';
import ImageSlider from '../../components/ImageSlider/index';
import SangriaCallout from '../../components/SangriaCallout/index';
import OrderModal from '../../components/OrderModal';
import Footer from '../../components/Footer/index';
import OnlineReservations from '../../components/OnlineReservations';

// Data
import FoodImagesData from '../../data/images/food-images';
import GenericImagesData from '../../data/images/generic-images';

function Home() {
  return (
    <>
      <Helmet>
        <title>Poco Piatti | Toledo's spot for mediterranean tapas.</title>
        <meta
          name="description"
          content="Mediterranean tapas restaurant and bar."
        />
      </Helmet>
      {/* <Curtain /> */}
      <Header light absolute />
      <HomeHero />
      <ThePocoExperience />
      <ImageSlider imageData={FoodImagesData} />
      <HensvilleCallout />
      <Locations />
      <SangriaCallout />
      <OnlineReservations />
      <ImageSlider imageData={GenericImagesData} />
      <OrderModal />
      <Footer />
    </>
  );
}

export default Home;
