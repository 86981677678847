const TheGardenPrivateEventsRoomData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4329.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4334.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4336.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4351.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4361.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4388.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4394.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4397.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4400.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4404.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4407.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4408.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4409.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4410.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4417.webp',
  },
];

export default TheGardenPrivateEventsRoomData;
