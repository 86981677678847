import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import PocoCrown from '../PocoCrown';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { color } = theme;

const SubPageHeroImage = styled.div`
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.65);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
`;

const SubPageHeroStyle = styled.div`
  position: relative;
  padding: 14em 0 3em 0;

  .inner-wrap {
    z-index: 1;
    text-align: center;
  }
`;

const GradientOverlay = styled.div`
  bottom: 0;
  height: 40em;
  left: 0;
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(2, 2, 10, 0),
    ${color.foreground}
  );
  mask-image: linear-gradient(180deg, rgba(2, 2, 10, 0), ${color.foreground});
  overflow-x: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;

  &:before {
    background-color: #02020a;
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

const SubpageHeroCopy = styled.div`
  ${mixins.innerWrap}
  padding: 3em 0;

  .title {
    margin-bottom: 2em;
  }

  p.large {
    color: ${color.foreground};
    text-align: left;
    margin-bottom: 0;
  }

  p.drop-cap:first-letter {
    float: left;
    font-size: 4rem;
    line-height: 2rem;
    margin-right: 0.25rem;
    position: relative;
  }
`;

const SubPageHero = ({ h1Text, imageUrl, light, title, copy }) => (
  <>
    <SubPageHeroStyle light={light}>
      <SubPageHeroImage imageUrl={imageUrl} />
      <div className="inner-wrap">
        <PocoCrown />
        <h1>{h1Text}</h1>
      </div>

      <GradientOverlay />
    </SubPageHeroStyle>
    {copy ? (
      <SubpageHeroCopy>
        <div className="title">{title}</div>
        <p className="large drop-cap">{copy}</p>
      </SubpageHeroCopy>
    ) : null}
  </>
);

SubPageHero.propTypes = {
  h1Text: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  light: PropTypes.bool,
  title: PropTypes.string,
  copy: PropTypes.string,
};

export default SubPageHero;
