import React from 'react';
import PropTypes from 'prop-types';

// Components
import AccordionPanel from './AccordionPanel';

const Accordion = ({ accordionData }) => (
  <>
    {accordionData.map((data) => (
      <AccordionPanel steps={data} />
    ))}
  </>
);

Accordion.propTypes = {
  accordionData: PropTypes.instanceOf(Object).isRequired,
};

export default Accordion;
