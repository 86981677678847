import React, { useState } from 'react';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import styled from 'styled-components';

// Data
import locationsData from '../../data/locations/locations-local';

// Components
import SectionHeading from '../SectionHeading/index';
import Location from '../Location/index';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { color } = theme;

const LocationsContainer = styled.section`
  svg {
    fill: none;
    stroke: ${color.callout};
    stroke-width: 4;
    stroke-miterlimit: 10;
    overflow: visible;
    width: 3.5em;

    &:first-of-type {
      margin-right: 0.65em;
    }

    &:last-of-type {
      margin-left: 0.65em;
    }
  }

  .dots {
    display: flex;
    padding: 2em 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid ${color.typeDim};
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    border: 1px solid ${color.foreground};
    background: ${color.foreground};
  }
`;

const LocationsSliderWrap = styled.div`
  ${mixins.wrap}
  cursor: grab;
  display: flex;
`;

const LocationsSliderItem = styled.div``;

function Locations() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      '(max-width: 840px)': {
        slides: { perView: 1, spacing: 15 },
      },
      '(min-width: 767px)': {
        slides: { perView: 1, spacing: 25 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 1.65, spacing: 35 },
      },
    },
  });

  return (
    <LocationsContainer>
      <div className="wrap">
        <SectionHeading
          title="Locations"
          text={[
            "TOLEDO'S SPOT",
            <br />,
            'FOR MEDITERRANEAN ',
            <span>tapas</span>,
          ]}
          id="locations"
        />
        <LocationsSliderWrap ref={sliderRef} className="keen-slider">
          {locationsData.map((image, key) => (
            <LocationsSliderItem key={key} className="keen-slider__slide">
              <Location data={image} />
            </LocationsSliderItem>
          ))}
        </LocationsSliderWrap>
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => (
            <button
              type="button"
              aria-label="slider button"
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              className={`dot${currentSlide === idx ? ' active' : ''}`}
            />
          ))}
        </div>
      )}
    </LocationsContainer>
  );
}

export default Locations;
