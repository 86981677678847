import { css } from 'styled-components';

// Fonts
// import yantramanavlightWoff from '../assets/fonts/yantramanav-light-webfont.woff';
// import yantramanavlightWoff2 from '../assets/fonts/yantramanav-light-webfont.woff2';
import saekanaRoughWoff from '../assets/fonts/saekana-rough-webfont.woff';
import saekanaRoughWoff2 from '../assets/fonts/saekana-rough-webfont.woff2';
import spValleySansRegularWoff from '../assets/fonts/sp-valley-sans-regular-webfont.woff';
import spValleySansRegularWoff2 from '../assets/fonts/sp-valley-sans-regular-webfont.woff2';
import spValleySansRoughWoff from '../assets/fonts/sp-valley-sans-rough-webfont.woff';
import spValleySansRoughWoff2 from '../assets/fonts/sp-valley-sans-rough-webfont.woff2';

import ebGaramondWoff from '../assets/fonts/ebgaramond-variablefont_wght-webfont.woff';
import ebGaramondWoff2 from '../assets/fonts/ebgaramond-variablefont_wght-webfont.woff2';

import ebGaramondItalicWoff from '../assets/fonts/ebgaramond-italic-variablefont_wght-webfont.woff';
import ebGaramondItalicWoff2 from '../assets/fonts/ebgaramond-italic-variablefont_wght-webfont.woff2';

const Fonts = css`


@font-face {
    font-family: 'eb_garamonditalic';
    src: url('${ebGaramondItalicWoff}') format('woff2'),
         url('${ebGaramondItalicWoff2}') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eb_garamondregular';
    src: url('${ebGaramondWoff2}') format('woff2'),
         url('${ebGaramondWoff}') format('woff');
    font-weight: normal;
    font-style: normal;

}
	@font-face {
		font-family: 'saekana_roughregular';
		src: url('${saekanaRoughWoff2}') format('woff2'),
				url('${saekanaRoughWoff}') format('woff'),
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
    font-family: 'saekana_cleanregular';
    src: url('saekana-clean-webfont.woff2') format('woff2'),
         url('saekana-clean-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'saekana_roughregular';
    src: url('${saekanaRoughWoff2}') format('woff2'),
         url('${saekanaRoughWoff}') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sp_valley_sansitalic';
    src: url('sp-valley-sans-italic-webfont.woff2') format('woff2'),
         url('sp-valley-sans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sp_valley_sansregular';
    src: url('${spValleySansRegularWoff2}') format('woff2'),
         url('${spValleySansRegularWoff}') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sp_valley_sansregular_rough';
    src: url('${spValleySansRoughWoff2}') format('woff2'),
         url('${spValleySansRoughWoff}') format('woff');
    font-weight: normal;
    font-style: normal;

}
`;

export default Fonts;
