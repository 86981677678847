import styled from 'styled-components';

// Styles
import theme from '../../styles/theme';

const { color } = theme;

const GradientBottomStyle = styled.div`
  bottom: 0;
  height: 40em;
  left: 0;
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(2, 2, 10, 0),
    ${color.background}
  );
  mask-image: linear-gradient(180deg, rgba(2, 2, 10, 0), ${color.background});
  overflow-x: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;

  &:before {
    background-color: ${color.background};
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

function GradientBottom() {
  return <GradientBottomStyle />;
}

export default GradientBottom;
