import React from 'react';

// Data
import faqData from '../../data/faq/faq-local.js';

// Components
import Accordion from './Accordion';

const Faq = () => (
  <section>
    <div className="inner-wrap">
      <h2>FAQ</h2>
      <Accordion accordionData={faqData} />
    </div>
  </section>
);

export default Faq;
