import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../../components/Header/index';
import SubPageHero from '../../components/SubPageHero/index';
import OrderCateringCallout from '../../components/OrderCateringCallout';
import OrderModal from '../../components/OrderModal';
import Locations from '../../components/Locations';
import Footer from '../../components/Footer/index';

// Images
import CateringImage from '../../assets/images/hummus-and-salad.png';

function Order() {
  return (
    <>
      <Helmet>
        <title>Order | Poco Piatti</title>
        <meta
          name="description"
          content="Mediterranean tapas restaurant and bar."
        />
      </Helmet>
      <Header light absolute />
      <SubPageHero
        h1Text={['ORDER', <span>Poco</span>]}
        imageUrl={CateringImage}
      />
      <OrderModal />
      <OrderCateringCallout />
      <Locations />
      <Footer />
    </>
  );
}

export default Order;
