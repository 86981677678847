import React from 'react';
import styled from 'styled-components';

// Components
import LinkExternal from '../LinkExternal/index';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { border, color } = theme;

const HensvilleCalloutContainer = styled.section`
  background-color: ${color.foreground};
`;

const HensvilleCalloutGrid = styled.div`
  ${mixins.wrap}

  border-radius: ${border.radius};
  overflow: hidden;
`;

const IngredientsBackgroundContainer = styled.div`
  background-image: url(https://thegardenbypocopiatti.com/wp-content/themes/thegarden/assets/images/the-garden-build-your-own-bundle.webp)};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 3em;
  padding-top: 30em;
  width: 100%;

  @media ${media.secondary} {
    padding-top: 16em;
  }
`;

const HensvilleCalloutGridContent = styled.div`
  background-color: ${color.foreground};
  text-align: center;

  h4 {
    max-width: 20em;
    text-align: center;
  }
`;

const TheGardenLinksNav = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${media.secondary} {
    flex-direction: column;
  }

  a {
    margin-right: 1.25em;

    @media ${media.secondary} {
      display: block;
      margin-bottom: 1.2em;
      width: 100%;
      text-align: center;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

function HensvilleCallout() {
  return (
    <HensvilleCalloutContainer>
      <IngredientsBackgroundContainer />
      <HensvilleCalloutGrid>
        <HensvilleCalloutGridContent>
          <div className="title dark">
            New Location! The Garden by Poco Piatti is now Open
          </div>
          <h2 className="dark">
            Fast casual <span>mediterranean</span>
          </h2>
          <p className="large">
            Made from scratch &mdash; made uniquely for you. The Garden by Poco
            Piatti is a fast casual Mediterranean restaurant. We are located
            inside Market Hall at the Glass City Metropark. Build your own bowl
            or pita wrap or choose one of our Chef's Favorites or brick oven
            pizzas. Enjoy flavors of the mediterranean at Glass City Metropark.
          </p>

          <TheGardenLinksNav>
            <LinkExternal
              text="The Garden"
              title="Visit The Garden by Poco Piatti's website."
              filled
              href="https://thegardenbypocopiatti.com/"
              target="_blank"
            />
            <LinkExternal
              text="Menu"
              href="https://thegardenbypocopiatti.com/menu/"
              title="View The Garden by Poco Piatti's menu."
              filled
              target="_blank"
            />
            <LinkExternal
              text="Metropark Info"
              href="https://metroparkstoledo.com/explore-your-parks/glass-city-metropark/"
              title="Visit Glass City Metropark's website."
              filled
              target="_blank"
            />
          </TheGardenLinksNav>
        </HensvilleCalloutGridContent>
      </HensvilleCalloutGrid>
    </HensvilleCalloutContainer>
  );
}

HensvilleCallout.propTypes = {};

export default HensvilleCallout;
