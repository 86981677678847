import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import LinkExternal from '../LinkExternal/index';

// Images
// import ToastLogo from '../../assets/images/toast-logo.svg';
// import GooglePlay from '../../assets/images/google-play.svg';
// import AppStore from '../../assets/images/app-store.svg';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';
import media from '../../styles/media';

const { color, border } = theme;

const OnlineReservationsStyle = styled.section`
  .order-modal-container {
    ${mixins.innerWrap}
    border: 1px solid ${color.typeDim};
    border-radius: ${border.radius};
    align-items: center;
    overflow: hidden;

    @media ${media.primary} {
      grid-template-columns: 1fr;
      overflow: hidden;
    }

    &-content {
      padding: 6em;

      @media ${media.primary} {
        padding: 3em;
      }

      @media ${media.secondary} {
        padding: 2.65em;
      }

      @media ${media.smallPhone} {
        padding: 2em;
      }

      h4 {
        margin-bottom: 1.25em;
      }
    }

    &-links {
      @media ${media.primary} {
        padding: 0;
      }
      .cta {
        text-align: center;
        width: 100%;

        margin-bottom: 0.5em;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

// const GridImage = styled.div`
//   background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-roasted-beet-salad.webp)};
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
// 	height: 100%;
// 	width: 100%;
// `;

// const MobileAppImage = styled.div`
// 	backdrop-filter: blur(15px);
//   background-image: url(https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-generic-iphone-mockup.webp)};
//   background-size: contain;
//   background-position-y: 22.5em;
// 	background-position-x: center;
//   background-repeat: no-repeat;
// 	border-radius: 0 ${border.radius} ${border.radius} 0;
// 	height: 100%;
// 	padding: 6em 0;
// 	text-align: center;
// 	width: 100%;

// 	@media ${media.primary} {
// 		padding: 6em 2em 19em;
//     background-size: 80%;
// 		border-radius: 0 0 ${border.radius} ${border.radius};
// 	}

// 	.title {
// 		margin-bottom: 1.65em;
// 	}

// 	img {
// 		width: 12em;
// 		margin-bottom: 2em;

// 	}
// `;

// const AppStoreContainer = styled.div`
//   a {
//     display: inline-block;
//     position: relative;

//     &:first-of-type {
//       margin-right: 1em;

//       @media ${media.primary} {
//         margin-right: 0.25em;
//       }
//     }

//     &:last-of-type {
//       margin-left: 1em;

//       @media ${media.primary} {
//         margin-left: 0.25em;
//       }
//     }

//     img {
//       height: 3rem;
//       width: auto;

//       @media ${media.primary} {
//         height: 2.25em;
//       }

//       @media ${media.smallPhone} {
//         height: 1.85em;
//       }
//     }
//   }
// `;

const OnlineReservations = ({ modal }) => (
  <OnlineReservationsStyle modal={modal}>
    <div className="order-modal-container">
      <div className="order-modal-container-content" id="online-reservations">
        <div className="title">Don't wait for a table</div>
        <h2>Reserve your table online</h2>
        <div className="order-modal-container-links">
          <LinkExternal
            text="Perrysburg"
            href="https://tables.toasttab.com/restaurants/edce7e9e-8849-41b0-bcbc-eae9ab523fcb/findTime"
            target="_blank"
          />
          <LinkExternal
            text="Sylvania"
            href="https://tables.toasttab.com/restaurants/b059174c-f3c8-44e6-bdbf-db732428c962/findTime"
            target="_blank"
          />
          {/* <LinkExternal
            text="The Garden"
            href="https://order.toasttab.com/online/poco-piatti-at-the-park-1521-front-street"
            target="_blank"
          /> */}
        </div>
      </div>
    </div>
  </OnlineReservationsStyle>
);

OnlineReservations.propTypes = {
  modal: PropTypes.bool.isRequired,
};

export default OnlineReservations;
