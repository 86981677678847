import React from 'react';
import { Helmet } from 'react-helmet';

// Data
import seafoodData from '../../data/food/seafood-local';
import carneData from '../../data/food/carne-local';
import vegetarianData from '../../data/food/vegetarian-local';
import bigPlatesData from '../../data/food/big-plates-local';
import pizzaData from '../../data/food/pizza-local';
import saladsData from '../../data/food/salads-local';
import sidesData from '../../data/food/sides-local';

// Components
import Header from '../../components/Header/index';
import SubPageHero from '../../components/SubPageHero';
import FoodSection from '../../components/FoodSection/index';
import OrderModal from '../../components/OrderModal';
import OrderCateringCallout from '../../components/OrderCateringCallout';
import Footer from '../../components/Footer/index';

// Images
import CateringImage from '../../assets/images/hummus-and-salad.png';

function Menu() {
  return (
    <>
      <Helmet>
        <title>Menu | Poco Piatti</title>
        <meta
          name="description"
          content="Mediterranean tapas restaurant and bar."
        />
      </Helmet>
      <Header light absolute />
      <SubPageHero
        title="Menu"
        h1Text={['FLAVORS', <span>of the</span>, 'MEDITERRANEAN']}
        imageUrl={CateringImage}
      />
      <section>
        <div className="inner-wrap">
          <FoodSection foodSectionHeading="Seafood" foodData={seafoodData} />
          <FoodSection foodSectionHeading="Carne" foodData={carneData} />
          <FoodSection
            foodSectionHeading="Vegetarian"
            foodData={vegetarianData}
          />
          <FoodSection
            foodSectionHeading="Big Plates"
            foodData={bigPlatesData}
          />
          <FoodSection foodSectionHeading="Pizza" foodData={pizzaData} />
          <FoodSection foodSectionHeading="Salads" foodData={saladsData} />
          <FoodSection foodSectionHeading="Sides" foodData={sidesData} />
        </div>
      </section>
      <OrderModal />
      <OrderCateringCallout />
      <Footer />
    </>
  );
}

export default Menu;
