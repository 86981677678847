const PerrysburgPrivateEventsRoomData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4239.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4241.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4245.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4251.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4255.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4260.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4269.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4273.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-4276.webp',
  },
];

export default PerrysburgPrivateEventsRoomData;
