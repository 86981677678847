const seafoodData = [
  {
    name: 'Shrimp Saganaki',
    desc: 'Sauteed with tomatoes, garlic, white wine, fresh oregano and feta.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Fried Calamari Zaatar',
    desc: 'Zaatar encrusted and served with spicy marinara.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Shrimp Asti-Spumante',
    desc: 'Sauteed with garlic, asti-spumante and parsley.',
    price: {
      small: 9,
      large: 16,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: "Thai Shrimp 'Lasagna'",
    desc: 'Layered with crispy wontons, spicy thai and coconut basil.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Blue Crab Lump Crab Cakes',
    desc: 'Served with roasted red pepper cream sauce.',
    price: {
      small: 12,
      large: 22,
    },
  },
  {
    name: 'Sumac Encrusted Tuna',
    desc: 'Served with tahini and diced cucumber salad.',
    price: {
      small: 12,
      large: 22,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
];

export default seafoodData;
