import React from 'react';
import styled from 'styled-components';

// Components
import LinkExternal from '../LinkExternal/index';
import ImageSlider from '../ImageSlider';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';

// Images
import PerrysburgPrivateEventsRoomData from '../../data/images/perrysburg-private-events-images';
import TheGardenPrivateEventsRoomData from '../../data/images/the-garden-private-events-images';

const LocationContainer = styled.div`
  padding: 0 0 6em 0;

  @media ${media.secondary} {
    padding: 3em 0;
  }
`;

const LocationGrid = styled.div`
  ${mixins.grid}
  grid-gap: 2em;
  min-height: 34em;
  overflow: hidden;
`;

const LocationDescription = styled.div``;

const LocationImage = styled.div`
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${media.secondary} {
    padding-top: 75%;
  }
`;

function OnsiteLocations() {
  return (
    <>
      {/* <section className="no-padding-top">
        <div className="wrap">
          <h2>Onsite Locations</h2>
          <div className="title">Types of events</div>
          <p>
            Business meetings, brunches, lunches, showers, rehearsals, weddings,
            anniversaries, birthdays, holiday parties, corporate events, etc.
          </p>
        </div>
      </section> */}
      <section className="no-padding-top">
        <div className="wrap">
          <div className="title">On-site Locations</div>
          <h2>
            Poco <span> Events Spaces</span>
          </h2>
          <p>
            Our private rooms are ideal for a variety of gatherings, including:
            business meetings, brunches and lunches, showers, rehearsal dinners,
            weddings, anniversaries, birthdays, holiday parties, corporate
            events, and more! Our elegant space features sophisticated decor,
            warm lighting, and all the amenities you need to make your event
            unforgettable.
          </p>
          <h4>Perrysburg</h4>
          <LocationContainer>
            <LocationGrid>
              <LocationImage imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-SEnkf.webp" />
              <LocationDescription>
                <h5>Perrysburg Private Event space</h5>

                <p>
                  <span>
                    $200 room rental fee with $1000 minimum between food &
                    beverage packages, not including tax, gratuity and event
                    fee. Max: 50
                  </span>
                </p>
                <LinkExternal
                  text="Book Perrysburg"
                  href="https://www.toasttab.com/catering/locations/98def64a-d299-4d88-b2ed-3a41ff005ad2"
                  title="Book the Perrysburg Private Event Space online."
                  light
                />
              </LocationDescription>
            </LocationGrid>
            <ImageSlider imageData={PerrysburgPrivateEventsRoomData} />
          </LocationContainer>
          <h4>Sylvania</h4>
          <LocationContainer>
            <LocationGrid>
              <LocationImage imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-kRdsM.webp" />
              <LocationDescription>
                <h5>Sylvania Patio</h5>

                <p>
                  <span>
                    $200 space rental fee with $1000 minimum between food &
                    beverage packages, not including tax, gratuity and event
                    fee. Max: 30
                  </span>
                </p>
                <LinkExternal
                  text="Book Sylvania Patio"
                  href="https://www.toasttab.com/catering/locations/98def64a-d299-4d88-b2ed-3a41ff005ad2"
                  title="Book the Sylvania Patio Event Space online."
                  light
                />
              </LocationDescription>
            </LocationGrid>
            {/* <ImageSlider imageData={CateringImagesData} /> */}
          </LocationContainer>
          <LocationContainer>
            <LocationGrid>
              <LocationImage imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/generic/poco-piatti-sylvania-large-party-table.webp" />
              <LocationDescription>
                <h5>Sylvania Full Restaurant</h5>

                <p>
                  <span>
                    Available Sundays between 11:30am - 4pm- $300 space rental
                    fee with a $1500 minimum between food & beverage packages,
                    not including tax, gratuity and event fee. Max: 125 whole
                    restaurant/ 30 corner section
                  </span>
                </p>
                <LinkExternal
                  text="Book Sylvania"
                  href="https://www.toasttab.com/catering/locations/98def64a-d299-4d88-b2ed-3a41ff005ad2"
                  title="Book the Sylvania full restaurant online."
                  light
                />
              </LocationDescription>
            </LocationGrid>
            {/* <ImageSlider imageData={CateringImagesData} /> */}
          </LocationContainer>
          <h4>Toledo</h4>
          <LocationContainer>
            <LocationGrid>
              <LocationImage imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-zJnbi.webp" />
              <LocationDescription>
                <h5>The Garden Patio</h5>
                <div className="title">Full Patio</div>
                <p>
                  <span>
                    $300 rental fee with a $1,000 food min Monday - Thursday,
                    $1,500 food minimum Fri - Sat - Sun between food and
                    beverage packages not including tax, gratuity and event fee.
                    Max 120
                  </span>
                </p>
                <div className="title">Half Patio</div>
                <p>
                  <span>
                    $150 half patio rental fee with a $500 min any day between
                    food and beverage packages not including tax, gratuity and
                    event fee. Max 50
                  </span>
                </p>
                <LinkExternal
                  text="Book The Garden"
                  href="https://www.toasttab.com/catering/locations/98def64a-d299-4d88-b2ed-3a41ff005ad2"
                  title="Book The Garden Patio Event Space online."
                  light
                />
              </LocationDescription>
            </LocationGrid>
            <ImageSlider imageData={TheGardenPrivateEventsRoomData} />
          </LocationContainer>
          <LocationContainer>
            <LocationGrid>
              <LocationImage imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-Y530H.webp" />
              <LocationDescription>
                <h5>Glass City Pavilion</h5>

                <p>
                  <span>
                    Rental through Metroparks (exclusive caterer starting, Jan
                    1, 2025)
                  </span>
                </p>
                <LinkExternal
                  text="Book Pavillion"
                  href="https://ohmetroparkstoledoweb.myvscloud.com/webtrac/web/search.html?Action=Start&SubAction=&_csrf_token=db970c0c0bd5f201409a8ab203a50666348010151de9dd0674353c8c1b15fc53&date=today&frwebsearch_search=Yes&begintime=12:00+am&frclass=&features=&location=&keyword=&keywordoption=Match+One&frheadcount=0&type=&subtype=&category=&blockstodisplay=24&features1=&primarycode=IN_GLASS+CITY+METROPARK_GLASS+CITY+PAVILION&features2=&features3=&features4=&features5=&features6=&features7=&features8=&sort=Location&display=Calendar&module=FR&multiselectlist_value=&frwebsearch_buttonsearch=Search"
                  title="Book the Glass City Pavillion online."
                  light
                />
              </LocationDescription>
            </LocationGrid>
            {/* <ImageSlider imageData={CateringImagesData} /> */}
          </LocationContainer>
        </div>
      </section>
    </>
  );
}

export default OnsiteLocations;
