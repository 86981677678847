import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import theme from '../../styles/theme';
import media from '../../styles/media';

const { responsive } = theme;

const SectionHeadingStyle = styled.div`
  margin-bottom: 3em;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  width: ${({ wrap }) => (wrap ? `${responsive.wrap}` : `100%`)};

  @media ${media.secondary} {
    width: ${({ wrap }) => (wrap ? `${responsive.wrapMobile}` : `100%`)};
    margin-bottom: 2em;
  }

  h2 {
    margin-right: 1em;

    @media ${media.secondary} {
      margin-right: 0;
    }
  }
`;

const SectionHeading = ({ title, text, light, wrap }) => (
  <SectionHeadingStyle wrap={wrap} light={light}>
    <div className="title">{title}</div>
    <h2>{text}</h2>
  </SectionHeadingStyle>
);

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  light: PropTypes.bool.isRequired,
  wrap: PropTypes.bool,
};

export default SectionHeading;
