import React from 'react';
import styled from 'styled-components';

// Components
import SectionHeading from '../SectionHeading';

// Styles
import theme from '../../styles/theme';
import media from '../../styles/media';

const { color } = theme;

const ReviewsStyle = styled.section`
  .review {
    color: ${color.typeDim};
    font-size: 1.85em;
    line-height: 1.25em;
    margin-bottom: 1em;
    max-width: 20em;

    @media ${media.secondary} {
      font-size: 1.35em;
    }
  }

  p.large {
    margin-bottom: 1em;
  }

  .reviewer {
    color: ${color.foreground};
    margin-bottom: 4em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Reviews = () => (
  <ReviewsStyle>
    <div className="inner-wrap">
      <SectionHeading
        title="Reviews"
        text={['POCO IN THE', <span>press</span>]}
      />
      <p className="large dim">
        <em>&quot;</em> The Mediterranean food at this restaurant with heavy{' '}
        <em>Lebanese and Italian influences</em> is so{' '}
        <em>beautifully prepared </em>
        that it seems like a more elite spot, but you can certainly find{' '}
        <em>great values.</em> <em>&quot;</em>
      </p>
      <div className="title reviewer">&mdash; The Blade</div>
      <p className="large dim">
        Dining Guide Award Winner for <em>Best Appetizers</em> & Runner-Up for{' '}
        <em>Best Staff</em>
      </p>
      <div className="title reviewer">&mdash; Toledo City Paper</div>
      <p className="large dim">
        Dining Guide Award Winner for <em>Best Restaurateur</em>
      </p>
      <div className="title reviewer">&mdash; Toledo City Paper</div>
    </div>
  </ReviewsStyle>
);

export default Reviews;
