import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import CirclePlus from '../CirclePlus';
import CircleMinus from '../CircleMinus';
import FoodItem from '../FoodItem/index';

// Styles
import theme from '../../styles/theme';
import media from '../../styles/media';
import mixins from '../../styles/mixins';

const { space, color, font } = theme;

const AccordionPanelContainer = styled.div`
  padding: 1.15em 0;
  border-bottom: 1px solid ${color.callout};
  width: 100%;

  .food-section-title {
    font-size: 1.65em;
    font-family: ${font.boldAlt};
    text-transform: uppercase;

    @media ${media.secondary} {
      font-size: 1.2rem;
    }

    @media ${media.smallPhone} {
      font-size: 1em;
    }
  }
`;

const AccordionPanelHead = styled.div`
  ${mixins.flexBetween};
`;

const AccordionPanelButton = styled.button`
  background: none;
  border: none;
  -webkit-appearance: none;
  padding: ${space.halfSpace};
`;

const AccordionPanelContent = styled.div`
  p {
    margin-bottom: ${space.halfSpace};
  }
`;

const FoodSection = ({ foodData, foodSectionHeading }) => {
  const [collapsed, expanded] = useState(true);

  const handleClick = () => (collapsed ? expanded(false) : expanded(true));

  return (
    <AccordionPanelContainer>
      <AccordionPanelHead>
        <div className="food-section-title">{foodSectionHeading}</div>
        <AccordionPanelButton onClick={handleClick}>
          <CirclePlus collapsed={collapsed} />
          <CircleMinus collapsed={collapsed} />
        </AccordionPanelButton>
      </AccordionPanelHead>
      <AccordionPanelContent className={collapsed ? 'collapsed' : 'expanded'}>
        {Object.keys(foodData).map((key) => (
          <FoodItem data={foodData[key]} />
        ))}
      </AccordionPanelContent>
    </AccordionPanelContainer>
  );
};

FoodSection.propTypes = {
  foodSectionHeading: PropTypes.string,
  foodData: PropTypes.shape({
    name: PropTypes.string,
    desc: PropTypes.string,
    price: {
      small: PropTypes.number,
      large: PropTypes.number,
    },
    dietary: {
      type: PropTypes.string,
      title: PropTypes.string,
    },
  }).isRequired,
};

export default FoodSection;
