import { createGlobalStyle } from 'styled-components';
import Resets from './resets';
import Fonts from './fonts';
import mixins from './mixins';
import media from './media';
import theme from './theme';

const { space, color, font } = theme;

const GlobalStyle = createGlobalStyle`
  ${Resets};
	${Fonts};
  ${mixins};
  ${media};

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    background-color: ${color.background};
		color: ${color.foreground};
    font-family: ${font.font};
    overflow-x: hidden;
  }

  body {
    position: relative;
  }

  section {
    padding: 6em 0;
    position: relative;
    clear: both;
    float: none;
    width: 100%;

    @media ${media.secondary} {
      padding: 3em 0;
    }
  }

	h1 {
		font-family: ${font.boldAlt};
		font-size: min(6em, 9vw);
		line-height: 1;
		margin-bottom: 0.25em;
		position: relative;
		color: ${color.foreground};

		span {
			color: ${color.callout};
			display: block;
			font-family: ${font.title};
			letter-spacing: 0;

			@media ${media.secondary} {
				font-size: 1.85em;
			}
		}
	}

  h2 {
		font-family: ${font.boldAlt};
		font-size: min(4em, 9vw);
		line-height: 1;
		margin-bottom: 0.65em;
		position: relative;
		color: ${color.foreground};
		text-transform: uppercase;

		

		span {
			color: ${color.callout};
			display: block;
			font-family: ${font.title};
			font-size: 1.5em;
			letter-spacing: 0;
			text-transform: initial;
		}

		a {
			color: ${color.callout};
		}
  }

	h2.dark {
		color: ${color.background};
	}

  h3 {
    color: ${color.background};
    margin-bottom: ${space.halfSpace};
    font-size: 2.25em;
    font-family: ${font.subHeading};
    line-height: 1.3em;

    @media ${media.secondary} {
      font-size: 1.65em;
    }
  }

  h4 {
    font-family: ${font.boldAlt};
		font-size: min(2.5em, 9vw);
		line-height: 1;
		margin-bottom: 0.65em;
		position: relative;
		color: ${color.foreground};
		text-transform: uppercase;

		span {
			color: ${color.callout};
			display: block;
			font-family: ${font.title};
			font-size: 1.85em;
			letter-spacing: 0;
			text-transform: initial;
		}

		a {
			color: ${color.callout};
		}
  }

	h5 {
		margin-bottom: ${space.quarterSpace};
    color: ${color.foreground};
    font-family: ${font.fontItalic};
    font-size: 2em;
	}

  p {
    font-family: ${font.font};
    color: ${color.typeDim};
    line-height: 1.6;
    font-size: 1.25em;;
		margin-bottom: 2rem;

		@media ${media.secondary} {
			font-size: 1.25em;
		}

		@media ${media.smallPhone} {
			font-size: 0.95em;
		}

		span {
			color: ${color.callout};
		}

    a {
      ${mixins.animate};
      color: ${color.callout};
      text-decoration: none;
      cursor: pointer;

			span {
				font: ${font.font};
			}

      &:hover {
        color: ${color.callout};
      }
    }

		em {
			font-family: ${font.bold};
			text-transform: uppercase;
			color: ${color.foreground};
			opacity: 0.75;
		}
  }

	p.large {
		font-size: 1.6em;
		line-height: 1.75;
		margin-bottom: ${space.space};

		@media ${media.secondary} {
			font-size: 1.25em;
		}

		@media ${media.smallPhone} {
			font-size: 0.95em;
		}
	}

	p.dim {
		color: ${color.typeDim};
	}

	p.dark {
		color: ${color.background};
	}

	a {
		i {
			font-family: monospace;
			font-size: 0.85em;
			color: ${color.typeAlt};
			font-style: italic;
			text-decoration: underline;
			text-decoration-color: ${color.foregroundAlt};
		}
	}

	i {
		font-family: ${font.fontItalic};
	}

	button {
		display: inline-block;
		border: none;
		padding: 1em 2em;
		margin: 0;
		text-decoration: none;
		background: none;
		color: none;
		cursor: pointer;
		text-align: center;
		-webkit-appearance: none;
		-moz-appearance: none;

		&:hover,
		&:focus {
			background: none;
		}

		&:focus {
			outline: none;
		}

		&:active {
			// transform: none;
		}
	}

  .wrap {
    ${mixins.wrap}
  }

  .inner-wrap {
    ${mixins.innerWrap}
  }

	.hide {
		display: none;
	}

  .collapsed {
    display: none;
  }

	.no-margin-bottom {
		margin-bottom: 0;
	}

  .no-padding-top {
    padding-top: 0;
  }

  .no-padding-bottom {
    padding-bottom: 0;
  }

	.dark {
		color: ${color.background};
	}

  .title {
   ${mixins.title}
  }

  .map {
    -webkit-filter: grayscale(100%);
  }

	.keen-slider {
		overflow: visible !important;
	}
`;

export default GlobalStyle;
