const sidesData = [
  {
    name: 'Hand-Cut French Fries',
    price: {
      small: 4,
      large: 8,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Rice Pilaf',
    price: {
      small: 3,
      large: 6,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Greek Potatoes',
    price: {
      small: 4,
      large: 8,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Gluten Free Pita Bread',
    desc: '5 pieces',
    price: 5,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
];

export default sidesData;
