import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import media from '../../styles/media';
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { color, font } = theme;

const LocationContainer = styled.div`
  border-radius: 1.2em;
  border: 1px solid ${color.typeDim};
  display: inline-block;
  position: relative;
  margin-bottom: 2em;
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media ${media.secondary} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const LocationContent = styled.div`
  color: ${color.typeDim};
  padding: 4em;

  @media ${media.primary} {
    padding: 3em;
  }

  @media ${media.secondary} {
    padding: 2em;
  }

  @media ${media.smallPhone} {
    padding: 1.65em;
  }

  p {
    line-height: 1;
    margin-bottom: 0.25em;
  }
`;

const LocationTitle = styled.div`
  color: ${color.foreground};
  font-family: ${font.fontItalic};
  font-size: 2.65em;
  line-height: 1;
  position: relative;
  margin-bottom: 1.3em;
  white-space: nowrap;

  @media ${media.secondary} {
    font-size: 2em;
  }

  @media ${media.smallPhone} {
    font-size: 1.65em;
  }
`;

const LocationMetaContainer = styled.div`
  margin-bottom: 2.25em;

  p {
    color: ${color.typeDim};
  }

  a {
    ${mixins.animate}
    color: ${color.foreground};
    font-family: ${font.fontItalic};
    font-size: 0.95em;
    margin-top: 0.3em;
    text-decoration: underline;

    &:hover {
      p {
        color: ${color.callout};
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const LocationImage = styled.div`
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;

  @media ${media.secondary} {
    padding-top: 60%;
  }
`;

const Location = ({ data }) => {
  const {
    name,
    addLine1,
    addLine2,
    mapLink,
    hrsLine1,
    hrsLine2,
    hrsLine3,
    hrsLine4,
    image,
  } = data;

  return (
    <LocationContainer>
      <LocationContent>
        <LocationTitle>
          <div>{name ? `${name}` : ''}</div>
        </LocationTitle>
        {addLine1 ? (
          <LocationMetaContainer>
            <div className="title">Address</div>

            <p>{addLine1 ? `${addLine1}` : ''}</p>
            <p>{addLine2 ? `${addLine2}` : ''}</p>
            <a
              href={mapLink}
              title="View location on Google maps."
              target="_blank"
              rel="noreferrer"
            >
              View on map
            </a>
          </LocationMetaContainer>
        ) : (
          <div className="title">Coming Soon</div>
        )}
        {hrsLine1 ? (
          <LocationMetaContainer>
            <div className="title">Hours</div>
            <p>{hrsLine1 ? `${hrsLine1}` : ''}</p>
            <p>{hrsLine2 ? `${hrsLine2}` : ''}</p>
            <p>{hrsLine3 ? `${hrsLine3}` : ''}</p>
            <p>{hrsLine4 ? `${hrsLine4}` : ''}</p>
          </LocationMetaContainer>
        ) : null}
      </LocationContent>
      <LocationImage imageUrl={image} />
    </LocationContainer>
  );
};

Location.propTypes = {
  data: PropTypes.shape({
    nmbr: PropTypes.string,
    name: PropTypes.string,
    addLine1: PropTypes.string,
    addLine2: PropTypes.string,
    mapLink: PropTypes.string,
    hrsLine1: PropTypes.string,
    hrsLine2: PropTypes.string,
    hrsLine3: PropTypes.string,
    hrsLine4: PropTypes.string,
    phone: PropTypes.string,
    image: PropTypes.string,
  }),
};

export default Location;
