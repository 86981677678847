import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import CateringPackages from '../../components/CateringPackages/index';
import BarPackages from '../../components/BarPackages';
import ALaCarte from '../../components/ALaCarte/index';
import Header from '../../components/Header/index';
import SubPageHero from '../../components/SubPageHero';
import OrderCateringCallout from '../../components/OrderCateringCallout/index';
import Footer from '../../components/Footer/index';
import ImageSlider from '../../components/ImageSlider';
import OnsiteLocations from '../../components/OnsiteLocations';

// Images
import CateringImagesData from '../../data/images/catering-images';

function Catering() {
  return (
    <>
      <Helmet>
        <title>Events & Catering | Poco Piatti</title>
        <meta
          name="description"
          content="Mediterranean tapas restaurant and bar."
        />
      </Helmet>
      <Header light absolute />
      <SubPageHero
        title="Private Events & Catering at Poco Piatti"
        h1Text={['LET US MAKE YOUR EVENT', <span>Unforgettable</span>]}
        imageUrl="https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/poco-piatti-private-events-pZKsL.webp"
        light
        copy="We know you love dining at Poco Piatti, and we're excited to bring that same exceptional experience to your special event. Host your next celebration at our beautifully appointed onsite
            locations, perfect for any occasion. "
      />
      <ImageSlider imageData={CateringImagesData} />
      <section className="no-padding-top">
        <div className="inner-wrap">
          <h2>
            <span>Catering</span>
            menus
          </h2>
          {/* <p>
            For those who prefer a different setting, we also offer convenient
            delivery and Curbside Catering pickup, ensuring you can enjoy Poco
            Piatti’s delicious food wherever you are. Let us help you create the
            perfect setting for your next celebration.
          </p> */}
          <CateringPackages />
          <BarPackages />
          <ALaCarte />
        </div>
      </section>
      <OnsiteLocations />
      <OrderCateringCallout />
      <Footer />
    </>
  );
}

export default Catering;
