import styled from 'styled-components';

// Components
import Icon from '../Icon/index';

// Styles
import theme from '../../styles/theme';
import mixins from '../../styles/mixins';
import media from '../../styles/media';

const { color } = theme;

const SunfooterContainer = styled.div`
  font-size: 0.8em;
  padding: 3em 0;

  @media ${media.secondary} {
    font-size: 0.65em;
  }

  @media ${media.smallPhone} {
    font-size: 0.55em;
  }
`;

const StyledCopyright = styled.div`
  color: ${color.typeDim};
  margin-bottom: 0.5em;
`;

const StyledSiteBy = styled.div`
  color: ${color.typeDim};
  display: flex;
  align-items: center;

  a {
    ${mixins.animate}
    display: inline-flex;

    svg {
      margin-left: 0.65em;
      margin-right: 0;
      height: 1.65em;
      width: 3.85em;

      &,
      use {
        fill: ${color.foreground};
      }
    }

    &:hover {
      svg {
        &,
        use {
          fill: ${color.callout};
        }
      }
    }
  }
`;

const year = new Date().getFullYear();

function Sunfooter() {
  return (
    <SunfooterContainer>
      <div className="wrap">
        <StyledCopyright>
          Copyright &#169; {year} Poco Piatti All Rights Reserved.
        </StyledCopyright>
        <StyledSiteBy>
          <div>Site by</div>
          {'  '}
          <Icon type="mm-logo" href="https://mattmaneval.com/" />.
        </StyledSiteBy>
      </div>
    </SunfooterContainer>
  );
}

export default Sunfooter;
