import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import media from '../../styles/media';
import theme from '../../styles/theme';

const { color } = theme;

const CircleMinusStyle = styled.svg`
  stroke: ${color.background};
  height: 2.85rem;
  width: 2.85rem;
  fill: ${color.foreground};
  vertical-align: middle;

  @media ${media.secondary} {
    height: 2.5rem;
    width: 2.5rem;
  }

  @media ${media.smallPhone} {
    height: 2rem;
    width: 2rem;
  }
`;

const CircleMinus = ({ collapsed }) => (
  <CircleMinusStyle
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={collapsed ? 'collapsed' : 'expanded'}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </CircleMinusStyle>
);

CircleMinus.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default CircleMinus;
