const FoodImagesData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-arayes-halabi.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-chicken-wraps.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-fatoosh.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-hummus.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-italian-sausage-pizza.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-lamb-chops.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-roasted-beet-salad.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-shrimp-saganaki.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/food/poco-piatti-food-stuffed-mushrooms.webp',
  },
];

export default FoodImagesData;
