import { css } from 'styled-components';
import media from './media';
import theme from './theme';

const { color, responsive, font } = theme;

const mixins = {
  // Wrap
  wrap: css`
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    width: ${responsive.wrap};

    @media ${media.secondary} {
      width: ${responsive.wrapMobile};
    }
  `,

  // Inner Wrap
  innerWrap: css`
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 42rem;
    width: ${responsive.wrap};

    @media ${media.secondary} {
      width: ${responsive.wrapMobile};
    }
  `,

  // Grid
  grid: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media ${media.secondary} {
      grid-template-columns: repeat(1, 1fr);
    }
  `,

  // Flex
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  flexBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  // Section
  section: css`
    padding: 6em 0;
    display: inline-block;
    position: relative;
    clear: both;
    float: none;
    width: 100%;

    @media ${media.secondary} {
      padding: 3em 0;
    }
  `,

  // Box Shadow
  boxShadow: css`
    box-shadow: 0 10px 25px 0 rgb(0 0 0 / 30%); ;
  `,

  // Animate
  animate: css`
    transition: 0.2s all ease-in-out;
  `,

  cta: css`
    cursor: pointer;
    display: inline-block;
    padding: 0.8em min(2.3em, 6vw);
    position: relative;
    text-transform: uppercase;
    line-height: 1;
    font-size: 1.15em;
    letter-spacing: 0.085em;
    font-family: ${font.bold};
    width: fit-content;

    @media ${media.primary} {
      padding: 0.65em min(1.2em, 6vw);
    }

    @media ${media.secondary} {
      font-size: 1em;
    }

    @media ${media.smallPhone} {
      font-size: 0.9em;
    }
  `,

  title: css`
    color: ${color.foreground};
    font-family: '${font.boldAlt}';
    font-size: 0.975em;
    margin-bottom: 1em;
    text-transform: uppercase;

    @media ${media.secondary} {
      font-size: 0.875em;
    }

    @media ${media.smallPhone} {
      font-size: 0.775em;
    }

    &.dark {
      color: ${color.background};
    }

    &.alt {
      color: ${color.typeAlt};
    }
  `,
};

export default mixins;
