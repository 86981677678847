import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages
import Home from './pages/Home/index';
import Menu from './pages/Menu/index';
// import PrivateEvents from './pages/PrivateEvents/index';
import Catering from './pages/Catering/index';
import About from './pages/About/index';
import Order from './pages/Order/index';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="menu" element={<Menu />} />
      {/* <Route path="private-events" element={<PrivateEvents />} /> */}
      <Route path="catering" element={<Catering />} />
      <Route path="about" element={<About />} />
      <Route path="order" element={<Order />} />
    </Routes>
  );
}

export default App;
