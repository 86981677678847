import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import CircleMinus from '../CircleMinus';
import CirclePlus from '../CirclePlus';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { space, color } = theme;

const CateringPackage = styled.div`
  margin-bottom: ${space.space};
`;

const AccordionPanelContainer = styled.div`
  padding: 1.15em 0;
  border-bottom: 1px solid ${color.callout};
  width: 100%;

  .title {
    margin-bottom: 0;
  }
`;

const AccordionPanelHead = styled.div`
  ${mixins.flexBetween};
`;

const AccordionPanelButton = styled.button`
  background: none;
  border: none;
  -webkit-appearance: none;
  padding: ${space.halfSpace};
`;

const AccordionPanelContent = styled.div`
  p {
    margin-bottom: ${space.halfSpace};
  }
`;

function BarPackages() {
  const [collapsed, expanded] = useState(true);

  const handleClick = () => (collapsed ? expanded(false) : expanded(true));

  return (
    <AccordionPanelContainer>
      <AccordionPanelHead>
        <div className="title">Bar Packages</div>
        <AccordionPanelButton onClick={handleClick}>
          <CirclePlus collapsed={collapsed} />
          <CircleMinus collapsed={collapsed} />
        </AccordionPanelButton>
      </AccordionPanelHead>
      <AccordionPanelContent className={collapsed ? 'collapsed' : 'expanded'}>
        <CateringPackage>
          <h5>Sangria Bar</h5>
          <p>
            White, red, and mixed sangria &mdash;{' '}
            <span>2 Hrs / 15 per person. 3 Hrs / 25 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Bubbly Bar</h5>
          <p>
            Assorted juices & champagne for mimosas, White, red, and mixed
            sangria &mdash;{' '}
            <span>2 Hrs / 18 per person. 3 Hrs / 28 per person</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Brew, Wine & Sangria</h5>
          <p>
            Import, domestic, and craft bottle beer. Red and white Poco wine and
            sangria &mdash;{' '}
            <span>
              2 Hrs / 20 per person. 3 Hrs / 30 per person. 10 per person evary
              additional hour.
            </span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Premium</h5>
          <p>
            Premium liquor, domestic, import, and craft beer options, Poco wine,
            and sangria &mdash;{' '}
            <span>
              2 Hrs / 35 per person. 3 Hrs / 45 per person. 15 per person every
              additional hour.
            </span>
          </p>
        </CateringPackage>
        <p>*Packages include fountain sodas</p>
      </AccordionPanelContent>
    </AccordionPanelContainer>
  );
}

export default BarPackages;
