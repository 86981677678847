import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import GlobalHeader from '../GlobalHeader/index';
import Logo from '../Logo/index';
import LinkInternal from '../LinkInternal/index';
import MenuButton from '../MenuButton/index';
import MobileMenu from '../MobileMenu/index';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';
import media from '../../styles/media';

const { color, font } = theme;

const StyledHeader = styled.header`
  position: ${({ absolute }) => (absolute ? `absolute` : `relative`)};
  width: 100%;
  ${({ absolute }) =>
    absolute
      ? `&:before {
					background-image: linear-gradient(#02020a, rgba(2, 2, 10, 0));
					content: '';
					height: 100%;
					position: absolute;
					width: 100%;
  			}`
      : null}
  z-index: 10;
`;

const StyledHeaderWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2.5rem;
  position: relative;
`;

const LogoContainer = styled(Link)``;

const NavContainer = styled.nav`
  @media ${media.primary} {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  ${mixins.animate}
  border-bottom: 2px solid transparent;
  break-inside: avoid;
  color: ${({ light }) =>
    light ? `${color.foreground}` : `${color.backgroundAlt}`};
  font-family: ${font.bold};
  font-size: 1.15em;
  letter-spacing: 0.085em;
  padding: 0.8em 1.15em;
  position: relative;
  text-transform: uppercase;

  @media ${media.secondary} {
    display: block;
  }

  &:hover {
    color: ${color.callout};
  }

  &:last-of-type {
    margin-right: 1.15em;
  }
`;

const HandleClickWrap = styled.div`
  display: inline-block;
`;

function Header({ light, absolute }) {
  const [menu, active] = useState(false);

  const handleClick = () => (menu ? active(false) : active(true));

  return (
    <StyledHeader absolute={absolute}>
      <GlobalHeader light={light} />
      <StyledHeaderWrap>
        <LogoContainer to="/">
          <Logo light={light} />
        </LogoContainer>
        <NavContainer>
          <StyledLink light={light} to="/menu">
            Menu
          </StyledLink>
          {/* <StyledLink light={light} to="/private-events">
            Private Events
          </StyledLink> */}
          <StyledLink light={light} to="/catering">
            Events & Catering
          </StyledLink>
          <StyledLink light={light} to="/about">
            About
          </StyledLink>
          <HandleClickWrap>
            <LinkInternal
              text="Order"
              title="Start an order at Poco Piatti."
              href="/order"
              light={light}
            />
          </HandleClickWrap>
        </NavContainer>
        <MenuButton menu={menu} onClick={handleClick} />
      </StyledHeaderWrap>
      <MobileMenu menu={menu} />
    </StyledHeader>
  );
}

Header.propTypes = {
  light: PropTypes.bool,
  absolute: PropTypes.bool,
};

export default Header;
